import _ from "lodash";

export const mockSiteEnvironments = [
  {
    id: "1",
    name: "Dev",
    integratedSystemId: "123e4567-e89b-12d3-a456-426614174000",
    fusionAppId: "123e4567-e89b-12d3-a456-426614174000",
    engineType: "FUSION",
    searchService: {
      endpoint: "search service",
      searchNeuralHybridSearchEnabled: true,
      searchLexicalWeight: 0.5,
      searchSemanticWeight: 0.5,
      boostWithSignalsEnabled: true,
      boostSignalsWeight: 5,
      boostFieldsEnabled: true,
      boostFields: [
        {
          field: "name",
          value: 5
        },
        {
          field: "title",
          value: 5
        }
      ],
      boostFreshnessEnabled: true,
      boostFreshnessWeight: 5,
      sortFields: [
        {
          field: "Relevance",
          order: "Ascending"
        },
        {
          field: "Date",
          order: "Descending"
        }
      ],
      synced: true,
    },
    typeaheadService: {
      endpoint: "typeahead endpoint",
      productAttributeBrandEnabled: true,
      productAttributeCategoryEnabled: true,
      synced: true,
    },
    creationDate: "2025-01-14T10:00:00Z"
  },
  {
    id: "2",
    name: "Prod",
    integratedSystemId: "123e4567-e89b-12d3-a456-426614174000",
    fusionAppId: "123e4567-e89b-12d3-a456-426614174000",
    engineType: "FUSION",
    searchService: {
      endpoint: "search service 2",
      searchNeuralHybridSearchEnabled: true,
      searchLexicalWeight: 0.5,
      searchSemanticWeight: 0.5,
      boostWithSignalsEnabled: true,
      boostSignalsWeight: 5,
      boostFieldsEnabled: true,
      boostFields: [
        {
          field: "name",
          value: 5
        },
        {
          field: "title",
          value: 5
        }
      ],
      boostFreshnessEnabled: true,
      boostFreshnessWeight: 5,
      sortFields: [
        {
          field: "Relevance",
          order: "Ascending"
        },
        {
          field: "Date",
          order: "Descending"
        }
      ],
      synced: true,
    },
    typeaheadService: {
      endpoint: "typeahead endpoint 2",
      productAttributeBrandEnabled: true,
      productAttributeCategoryEnabled: true,
      synced: true,
    },
    creationDate: "2025-01-14T10:00:00Z"
  }
]

const createMockCustomerSites = (index: number) => {
  return {
    id: `site${index + 1}`,
    name: `Site ${index + 1}`,
    creationDate: `2025-01-${Math.floor(Math.random() * 31) + 1}T11:00:00Z`,
    lastModified: `2025-01-${Math.floor(Math.random() * 31) + 1}T11:00:00Z`,
  };
}

export const mockCustomerSitesContent = (): any => {
  const sites = 50;
  return {
    content:   _.times(sites, (index) => createMockCustomerSites(index)),
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      pageNumber: 0,
      pageSize: 10,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    totalElements: sites,
    numberOfElements: sites,
    size: 10,
    number: 0,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
  }
}

let mockResults: any;

export const mockCustomerSites = (filter: any = { page: 0, size: 10 }): any => {
  if (_.isEmpty(mockResults)) {
    mockResults = mockCustomerSitesContent();
  }

  if (!filter?.page) {
    filter.page = 0;
  }
  const filteredContent = _.slice(mockResults.content, filter.page * filter.size, (filter.page + 1) * filter.size);

  return {
    ...mockResults,
    content: filteredContent,
    pageable: {
      ...mockResults.pageable,
      sort: {
        ...mockResults.pageable.sort,
        empty: filteredContent.length === 0,
      },
      pageNumber: filter.page,
      pageSize: filter.size,
      offset: filter.page * filter.size,
    },
    numberOfElements: filter.size,
    size: filter.size,
    number: filter.page,
    sort: {
      ...mockResults.sort,
      empty: filteredContent.length === 0,
    },
  };
}