import { mockCustomerSites, mockSiteEnvironments } from '../../components/lw-sites/mock-data';
import { CCApiService } from './api-service';
import { UIConfig } from './interfaces';

export class SitesService {
  static ApiService: CCApiService;
  static sitesAPI: string;
  static apiBasePath: string;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.sitesAPI = `${this.apiBasePath}/customers/${customerId}/sites`;
  }

  static async getCustomerSites({ mock }, filter: {}): Promise<any> {
    if (mock) {
      return Promise.resolve(mockCustomerSites(filter));
    }
    return this.ApiService.performFetch(`${this.sitesAPI}?${new URLSearchParams(filter)}`).then(sites => sites);
  }

  static async getSiteEnvironments({ siteId, mock }): Promise<any> {
    if (mock) {
      return Promise.resolve({content: mockSiteEnvironments});
    }
    return this.ApiService.performFetch(`${this.sitesAPI}/${siteId}/environments`).then(sites => {
      return sites;
    });
  }
}
